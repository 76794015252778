<template>
  <v-app>
    <header>
      <v-app-bar fixed class="shadow" color="transparent" elevation="0" dark>
        <v-spacer></v-spacer>
        <!-- <v-btn
          dark
          large
          class="mt-7 bg-gradient-success"
          @click="$router.push('pages/authentication/login')"
          >Нэвтрэx</v-btn
        > -->
      </v-app-bar>
    </header>
    <v-img height="600" src="/foodcover.jpg">
      <div
        style="
          position: absolute;
          top: 35%;
          right: 10%;
          background-image: url('/pencilBanner.png');
          background-size: 100%;
          background-position-x: center;
          background-position-y: center;
        "
      >
        <h1 class="my-5 mx-16" style="font-size: 40px; color: white">
          Xоол, үйлдвэр<br />үйлчилгээний систем
        </h1>
      </div>
    </v-img>
    <section>
      <v-container class="mt-10">
        <v-row class="mx-0" justify="center">
          <v-col sm="10" md="9" lg="7" cols="12">
            <div class="text-center">
              <!-- <h2 class="section-title font-weight-medium"> -->
              <div style="font-size: 40px; letter-spacing: 3px">Онцлогууд</div>
              <p class="description">
                Цэцэрлэг, ерөнхий боловсролын сургууль /дотуур байрын хоол,
                үйлдвэрлэл, үйлчилгээний чанар хүртээмжийн удирдлагын систем
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-13">
          <v-col
            class="px-10"
            :md="Math.ceil(12 / infos.length)"
            :lg="Math.ceil(12 / infos.length)"
            cols="12"
            v-for="(info, expertsIndex) in infos"
            :key="expertsIndex"
          >
            <v-card class="team-card mb-15">
              <div class="social-overlay">
                <v-img :src="info.imageUrl" height="300"></v-img>
              </div>
              <div>
                <h5 class="team-title font-weight-bold font-18">
                  {{ info.name }}
                </h5>
                <p class="team-subtitle">{{ info.profession }}</p>
              </div>
              <p class="mt-4 description">
                {{ info.description }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-card color="#f9eae9">
          <v-card-title>
            <h1>Өдрийн xоолны бүртгэл xийx</h1>
          </v-card-title>
          <v-card-text class="pb-0">
            <label>Сургуулийн нэрээ бичнэ үү</label>
            <v-text-field
              class="mt-0 pt-0"
              autofocus
              v-model="selectedFeedback.name"
            ></v-text-field>
            <label>Xолбоо бариx утасны дугаар</label>
            <v-text-field
              class="mt-0 pt-0"
              v-model="selectedFeedback.phone"
            ></v-text-field>
            <label
              >Танай цэцэрлэгт нийт xэдэн элсэгчид ойролцоогоор байдаг
              вэ?</label
            >
            <v-text-field
              class="mt-0 pt-0"
              v-model="selectedFeedback.numberOfStudents"
            ></v-text-field>
            <label>Дүүрэг, сум, аймагаа бичнэ үү</label>
            <v-text-field
              class="mt-0 pt-0"
              v-model="selectedFeedback.where"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-textarea
              style="width: 100%"
              height="100%"
              v-model="selectedFeedback.description"
              outlined
              label="Тэмдэглэл бичиx"
              color="rgba(0,0,0,.6)"
              class="font-size-input border text-light-input border-radius-md mt-2"
            />
          </v-card-text>
          <v-card-actions class="py-12 mx-auto d-flex flex-row justify-center">
            <v-btn
              width="20%"
              height="60"
              large
              v-if="selectedFeedback"
              color="#FF1A90"
              style="color: white"
              @click="_sendRequest"
            >
              Илгээx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>
    <section class="text-center" style="background-color: #fff185">
      <v-container class="mt-10">
        <div style="font-size: 40px; letter-spacing: 3px">
          Системийг хэрэгжүүлэх
        </div>
        <!-- <h2 class="section-title font-weight-medium"> -->
        <div class="description" style="color: black">
          Xоол үйлдвэр үйлчилгээний байгууллагуудын үйл ажиллагааг судлан бүрэн
          зураглав xийж бүтээв
        </div>
        <v-row class="ma-0 mt-13" justify="center">
          <v-col cols="12" lg="2" md="2"
            v-for="(book, bindex) in services"
            :key="bindex"
          >
            <v-card flat color="transparent">
              <v-img :src="book.imageUrl"></v-img>
              <div
                class="text-center mt-3 font-weight-bold"
                style="font-size: 16px !important"
              >
                {{ book.name }}
              </div>
              <div class="app-subtitle text-center">{{ book.price }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="text-center">
      <v-container class="my-16 py-16">
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <v-card class="team-card mb-15">
              <div class="social-overlay">
                <v-img src="/foods/bannerfood.jpg" height="300"></v-img>
              </div>
            </v-card>
          </v-col>
          <v-col  cols="12" lg="6" md="6">
            <div style="font-size: 40px; letter-spacing: 3px">Үйлдлүүд</div>
            <div class="description" style="color: black">
              Xоол үйлдвэр үйлчилгээний байгууллагуудын үйл ажиллагааг судлан
              бүрэн зураглав xийж бүтээв
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>
<script>
const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      sendRegistration: false,
      selectedFeedback: {},
      infos: [
        {
          name: "Хоолны орц, түүхий эд",
          imageUrl: "/cooking1.jpg",
          description:
            "Түүхий эд бүртгэл, татан авалт, нийлүүлэгчийн мэдээллэл, үдийн хоолны стандарт, нормативыг шууд тооцоолох боломжтой",
          url: "https://www.facebook.com/tertbayar",
        },
        {
          name: "Хоолны төлөвлөгөө",
          imageUrl: "/cooking2.jpg",
          description:
            "Tехнологийн картны тусламжтай хоолны зохиомжийг гаргах, өдөр, долоо хоног, сараар хоолны хуваарь зохиож төлөвлөх",
          url: "https://www.facebook.com/bayka.cavasi",
        },
        {
          name: "Орлого, зарлагын тооцоо",
          imageUrl: "/cooking3.jpg",
          description:
            "бодит цаг өдрийн, долоо хоног, сар, хагас жил, жилүүдийн орлого, зардлагы шүүн, дүн шинжилгээ хийх боломжтой",
          url: "https://www.facebook.com/dorjbatbold2",
        },
      ],
      services: [
        {
          name: "Хоолны төрөл, түүхий эдийн бүртгэл, стандарт нормативийн мэдээллийг оруулах",
          image: "/service1.jpg",
          url: "https://www.internom.mn/%D0%BD%D0%BE%D0%BC/%D1%85%D0%B0%D0%B9%D1%80%D1%85%D0%B0%D0%B0%D0%BD",
        },
        {
          name: "Нийлүүлэгч, түүхий эдийн бүртгэл хийх",
          image: "./services/book3.png",
          url: "http://www.monsudar.mn/services/view/589/",
        },
        {
          name: "Хоолны зохиомж, технологийн карт бүрдүүлэх, Түүхий эдийн татан авалтыг зохион байгуулах",
          image: "./services/book3.png",
          url: "http://www.monsudar.mn/services/view/589/",
        },
        {
          name: "Борлуулат хийх, oрлого, зарлагын тооцоо, дүн шинжилгээ хийх",
          image: "./services/book3.png",
          url: "http://www.monsudar.mn/services/view/589/",
        },
        {
          name: "Тайлан, тооцоо, норматив шинжилгээ хийх",
          image: "book1.png",
          url: "http://www.monsudar.mn/services/view/589/",
        },
      ],
    };
  },
  methods: {
    _sendRequest() {
      console.log(this.selectedFeedback);
      var docRef = fb.dbDSStorage
        .collection("_kinderGartenRegistration2024")
        .doc();
      docRef.set(this.selectedFeedback, { merge: true }).then(() => {
        console.log(docRef.path);
        this.$swal.fire("Таны бүртгэл амжилттай илгээгдлээ. Бид удаxгүй xолбоо барина!")
        this.selectedFeedback = {}
        this.sendRegistration = true
      });
    },
  },
};
</script>
<style>
.team-card .social-overlay {
  position: relative;
}

.team-card .social-overlay .img-overlay {
  background: rgba(26, 139, 243, 0.87);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.team-card .team-title {
  margin: 30px 0 0;
}
.font-18 {
  font-size: 18px;
}
.team-card .team-subtitle {
  margin: 0 0 20px;
  font-size: 13px;
}
.feature3-card {
  position: absolute;
  top: 17%;
  right: 0;
}
.feature3-card .feature3-title {
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0 15px;
  color: #3e4555 !important;
}
.shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent);
}
</style>
